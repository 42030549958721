import React from "react";
import AddressSection from "./../components/AddressSection";
import {Elements} from "@stripe/react-stripe";
import ApplePaySection from "../components/ApplePaySection";
import { useAuth } from "./../util/auth.js";
import { useRouter } from "./../util/router.js";

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

function ShippingPage(props) {
    const stripe = window.Stripe(REACT_APP_STRIPE_PUBLIC_KEY);
    const auth = useAuth();
    const getUser = () => {return auth.user};

    const router = useRouter();

    // Go to page after signup
    const onDone = () => {
        router.push("/dashboard");
    };

    const onApplePay = (event) => {
        if (event.paymentMethod) {
            auth.createCustomer(getUser(), event.paymentMethod.id, null, null);
        }
        if (event.shippingAddress) {
            let sa = event.shippingAddress;
            auth.updateStripeUserMetadata({
                "shippingName": sa.recipient,
                "shippingLine1": sa.addressLine[0],
                "shippingCity": sa.city,
                "shippingState": sa.region,
                "shippingCountry": sa.country,
                "shippingPostalCode": sa.postalCode,
                "shippingPhone": sa.phone,
                "isApplePay": "true"
            }, {"address": {"line1": sa.addressLine[0],
                            "city": sa.city,
                            "country": sa.country,
                            "postal_code": sa.postalCode,
                            "state":sa.state},
                "name": sa.recipient,
                "phone": sa.phone});
        }

        event.complete('success');
        onDone();

    };

    return (
        <Elements stripe={stripe}>
        <ApplePaySection stripe={stripe} onPaymentMethod={onApplePay}/>
        <AddressSection
            onDone={onDone}
            color="white"
            size="large"
            title="Provide your shipping address"
            subtitle=""
            buttonText="Next"
            mode="shipping"
        />
        </Elements>
    );
}

export default ShippingPage;
