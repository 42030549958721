import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Features from "./Features";

const SITE = 'dps.ngrok.io';

function FeaturesSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        /> */}
        <Features
          items={[
            {
              title: "New: Quarterly Subscription",
              description: "No need to carry around a journal and a notebook - subscribe and get your journal delivered every 3 months with just the planner pages you need and tons of notes pages. Seasonal updates to the cover images, and the ability to upload new custom images for the upcoming quarter keep things fresh.",
              image: "/svg/inbox.svg"
            },
            {
              title: "Story",
              description: "I like to write things down. With a pen. It helps me remember and fully internalise whatever it is I'm writing about. Over the years, I developed a system of working that helps me achieve my goals. In 2015, I decided to make a paper journal which reflects that system. Not just a diary, but a structured scratchpad that is designed to help me work the way I want to. I found it really useful. I gave copies to a bunch of friends and co-workers and they found it useful too. They also had lots of feedback and ideas which I've incorporated in several revisions. Now, you can use it too!",
              image: "/svg/architect.svg"
            },
            {
              title: "Month & Week Planner",
              description:
                "You can use Founder's Journal as a diary - it has sections for month and week planning. You can also define what you want to get done at the start of each week and reflect on your progress at the end. The daily planner layout is flexible - you can choose to use all the space for action tracking or use the left hand column for each day to keep track of your appointments.",
              image: "/svg/calendar.svg"
            },
            {
              title: "Objectives & Graphs",
              description:
                "This is where the Journal really comes into its own. There are pages for setting useful and measurable annual, quarterly, monthly and weekly goals. They repeat at the right interval to keep you on track. There are also special chart pages where you will plot your progress frequently. The act of looking up the latest numbers and plotting them with a pen helps you really pay attention to what's going on - to reflect on your accomplishments and take action when things aren't going so well.",
              image: "/svg/analyza_data_and_chart.svg"
            },
            {
              title: "Extras",
              description:
                "There are pages designed for note taking, sketching and wireframing, tables of useful information and more.",
              image: "/svg/designer_2.svg"
            },
            {
              title: "Specs",
              description:
                "Size: A5 (210 x 142 mm) Paper: 200 pages, 100 gsm acid-free paper Binding: black plastic spiral bound Cover & Back: Full color custom images",
              image: "/svg/open_maps.svg"
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default FeaturesSection;
