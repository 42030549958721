import React from "react";
import SignUpSection from "./../components/SignUpSection";

function SignupPage(props) {
  return (
    <SignUpSection
      color="white"
      size="large"
      title="Get yourself an account"
      subtitle=""
      buttonText="Sign up"
      onboardingTo={props.onboardingTo}
    />
  );
}

export default SignupPage;
