import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Checkout from "./Checkout";
import { Elements } from "react-stripe-elements";
import { useAuth } from "./../util/auth.js";
import CoverChooseSection from "./CoverChooseSection";
import ManageSubscriptionSection from "./ManageSubscriptionSection";
import OrderHistorySection from "./OrderHistorySection";
import moment from "moment";
import JournalCoverPreview from "./JournalCoverPreview";
import { currencyToSymbol, coversForStripeCustomer } from "./../util/utils.js";
import { useCookies } from 'react-cookie';
import SectionButton from "./SectionButton";
import { useRouter } from "./../util/router.js";
import { Link } from "./../util/router.js";

function DashboardSection(props) {
  const [cookies, setCookie] = useCookies(['fj']);

  const auth = useAuth();
  const subscribed = (auth.stripeUser &&
    auth.stripeUser.subscriptions &&
    auth.stripeUser.subscriptions.data[0] &&
    auth.stripeUser.subscriptions.data[0].items.data[0].plan.active);
  const annual = (auth.stripeUser && auth.stripeUser.metadata && !subscribed);
  const paymentProvisioned = (auth.stripeUser && auth.stripeUser.invoice_settings && auth.stripeUser.invoice_settings.default_payment_method);
  const loading = !(auth.stripeUser)
  const covers = (auth.stripeUser &&
    auth.stripeUser.metadata && auth.stripeUser.metadata.covers) || "seasonal";

  const {frontUrl, backUrl} = coversForStripeCustomer(auth.stripeUser);
  const onboarding = (auth.stripeUser &&
    auth.stripeUser.metadata && auth.stripeUser.metadata.onboarding) || cookies.onboarding;

  const router = useRouter();

  const stripeMeta = (field) => {
    return (auth.stripeUser && auth.stripeUser.metadata &&
      auth.stripeUser.metadata[field]) || "";
  }

  const timestampToDate = (timestampStr) => {
    return moment.unix(parseInt(timestampStr)).format("YYYY-MM-DD");
  }

  return (
    <>
    { loading || (
    <Section color={props.color} size={props.size}>
      <div className="container">
      {(subscribed || annual) && (
        <>
        <SectionHeader
          title={props.title}
            subtitle={subscribed &&
              ("Next journal will be sent for printing on " + timestampToDate(JSON.stringify(auth.stripeUser.subscriptions.data[0].current_period_end)))}
          centered={true}
          tight={true}
          size={3}
        />
        { !paymentProvisioned && (
        <Elements>
          <>
        <SectionHeader
          title="Please enter your credit card details"
            subtitle="You'll be charged when the journal is sent for printing. You can cancel at any time."
          centered={true}
          size={4}
        />
        <Checkout
          parentColor={props.color}
          user={props.user}
          buttonText="Save"
          onboardingTo={props.onboardingTo} />
          </>
        </Elements>
        )}
        {( annual && paymentProvisioned) &&
        <>
        <div className="columns is-centered">
        <div className="column is-half">
          <SectionButton size="medium" fullWidth={true}
            onClick={(e) => {
              auth.chargeInvoice();
            }}
          >Buy Now $19.99</SectionButton>
        </div>
        </div>
        {( stripeMeta("pending_order") && 
                    <div className="Auth__bottom-link has-text-centered">
                      {
                        <>
                          Order placed, preparing to print.
                        </>
                      }
                    </div>

      )}
        {/* <div className="Auth__bottom-link has-text-centered">
            {
              <>
                Want to subscribe instead?
                <Link to="/pricing">Pricing</Link>
              </>
            }
        </div> */}

              <div className="ManageSubscriptionSection__container container">

              <h1 className="title">Shipping Address</h1>
              <h2 className="subtitle">{<><div>{stripeMeta("shippingName")}</div>
                  <div>{stripeMeta("shippingLine1")}</div>
                  <div>{stripeMeta("shippingLine2")}</div>
                  <div>{stripeMeta("shippingCity")}</div>
                  <div>{stripeMeta("shippingState")}</div>
                  <div>{stripeMeta("shippingPostalCode")}</div>
                  <div>{stripeMeta("shippingCountry")}</div></>}</h2>
              <div className="ManageSubscriptionSection__button">
                <SectionButton size="medium"
                  onClick={(e) => {router.push("/shipping");}}
                >Change</SectionButton>
            </div>
            </div></>
          }
        </>
        )}

        <CoverChooseSection title="Change covers"/>
        <JournalCoverPreview frontUrl={frontUrl} backUrl={backUrl}/>
        
        {
          auth.stripeUser.invoices && auth.stripeUser.invoices.data && (auth.stripeUser.invoices.data.length > 0) && (
            <OrderHistorySection title="Order History" invoices={auth.stripeUser.invoices.data} />
          )
        }

        {( subscribed &&
        <ManageSubscriptionSection title="Manage Subscription" subtitle={subscribed &&
              ("You're subscribed to " + auth.stripeUser.subscriptions.data[0].items.data[0].plan.nickname +
              " @ " + currencyToSymbol(auth.stripeUser.subscriptions.data[0].items.data[0].plan.currency) +
               (auth.stripeUser.subscriptions.data[0].items.data[0].plan.amount/100))}
                  subscription={auth.stripeUser.subscriptions.data[0].id}
                  shipping={<><div>{stripeMeta("shippingName")}</div>
                    <div>{stripeMeta("shippingLine1")}</div>
                  <div>{stripeMeta("shippingLine2")}</div>
                  <div>{stripeMeta("shippingCity")}</div>
                  <div>{stripeMeta("shippingState")}</div>
                  <div>{stripeMeta("shippingPostalCode")}</div>
                  <div>{stripeMeta("shippingCountry")}</div></>}
               />
        )}
        { !subscribed && !onboarding && (
              <SectionHeader
                title={props.title}
                subtitle="You are not subscribed right now."
                centered={true}
                tight={true}
                size={3}
              />
        )}
      </div>
    </Section>
    )}
    </>
  );
}

export default DashboardSection;
