import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Pricing from "./Pricing";
import "./PricingSection.scss";
import { useRouter } from "./../util/router.js";
import { useCookies } from 'react-cookie';
import { useAuth } from "./../util/auth.js";

function PricingSection(props) {
  const [cookies, setCookie] = useCookies(['fj']);

  const router = useRouter();
  const auth = useAuth();

  // Go to page after signin
  const onSubscribe = (plan) => {
    if (auth.stripeUser) {
      auth.updateStripeUserMetadata({onboarding: plan});
      if (auth.stripeUser.invoice_settings && auth.stripeUser.invoice_settings.default_payment_method) {
        auth.createSubscription();
      }
    }
    router.push("/dashboard");
  };
  
  return (
    <Section color={props.color} size={props.size} id="pricing">
      <div className="PricingSection__container container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Pricing
          buttonText="Choose"
          onChoosePlan={planId => {
            props.setOnboarding(planId);
            setCookie('onboarding', planId);
            onSubscribe(planId);
          }}
          items={[
            {
              id: "year",
              timespan: "Buy 2022 Journal",
              per: "journal",
              price: "19.99",
              cta: "Buy",
              description: "Full year in a single journal. Includes delivery."
            }
          ]}
        />
      </div>
    </Section>
  );
}

export default PricingSection;
