import React from "react";
import us_states from "./usstates.json";

function FormField(props) {
  return (
    <div className="field">
      <div className="control">
        {props.type === "textarea" && (
          <textarea
            className="textarea is-medium"
            type={props.type}
            value={props.value}
            placeholder={props.placeholder}
            onChange={e => props.onChange(e.target.value)}
          />
        )}
        {props.type === "select" && (
          <select
            className="input is-medium"
            type={props.type}
            value={props.value}
            placeholder={props.placeholder}
            onChange={e => props.onChange(e.target.value)}
          >
            <option value="AU">Australia</option>
            <option value="CA">Canada</option>
            <option value="FR">France</option>
            <option value="DE">Germany</option>
            <option value="IE">Ireland</option>
            <option value="JP">Japan</option>
            <option value="MX">Mexico</option>
            <option value="NL">The Netherlands</option>
            <option value="NZ">New Zealand</option>
            <option value="SG">Singapore</option>
            <option value="GB">United Kingdom</option>
            <option value="US">United States</option>

          </select>
        )}
        {props.type === "select-state" && (
          <select
            className="input is-medium"
            type="select"
            value={props.value}
            placeholder={props.placeholder}
            onChange={e => props.onChange(e.target.value)}
          >
            {us_states.map( (item) => (
            <option value={item[0]}>{item[1]}</option>
            ))}

          </select>
        )}
        {props.type !== "textarea" && props.type !== "select" && props.type !== "select-state" && (
          <input
            className="input is-medium"
            type={props.type}
            value={props.value}
            placeholder={props.placeholder}
            onChange={e => props.onChange(e.target.value)}
          />
        )}
      </div>

      {props.error && <p className="help is-danger">{props.error.message}</p>}
    </div>
  );
}

export default FormField;
