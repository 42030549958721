import React, { useEffect } from "react";
import DashboardSection from "./../components/DashboardSection";
import { useAuth } from "./../util/auth.js";
import { useRouter } from "./../util/router.js";

function DashboardPage(props) {
  const auth = useAuth();
  const router = useRouter();

  // Redirect to signin
  // if not signed in.
  useEffect(() => {
    if (auth.user === false) {
      router.push("/signup");
    }
  }, [auth, router]);

  return (
    <DashboardSection
      color="white"
      size="small"
      title={"👋 " + (auth.user ? auth.user.displayName : "")}
      subtitle=""
      user={auth.user}
      onboardingTo={props.onboardingTo}
      setOnboardingTo={props.setOnboardingTo}
      />
  );
}

export default DashboardPage;
