import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./CoverChooseSection.scss";
import { useAuth } from "./../util/auth.js";
import UploadCovers from "./UploadCovers";
import JournalCoverPreview from "./JournalCoverPreview";
import SectionButton from "./SectionButton";


function CoverUploadSection(props) {
  const auth = useAuth();
  const loading = !(auth.stripeUser);
  const frontUrl = (auth.stripeUser &&
    auth.stripeUser.metadata && auth.stripeUser.metadata.frontUrl) || "http://singleton.io/2020front.jpg";
  const backUrl = (auth.stripeUser &&
    auth.stripeUser.metadata && auth.stripeUser.metadata.backUrl) || "http://singleton.io/2020back.jpg";

  const onFrontUpload = url => {auth.updateStripeUserMetadata({frontUrl: url})};
  const onBackUpload = url => {auth.updateStripeUserMetadata({ backUrl: url })};

  return (
      <>
      <Section color={props.color} size={props.size} id="preview">
        <SectionHeader
          title={props.title}
          subtitle=""
          centered={true}
          size={3}
        />
      </Section>
      {(loading && <SectionHeader
        title="Loading..."
        subtitle=""
        centered={true}
        size={3}
      />) || 
    <Section color={props.color} size={props.size} id="covers">
      <div className="CoverChooseSection__container container">
        
        <UploadCovers
          buttonText="Upload"
          loading={loading}
          selected=""
          items={[
            {
              id: "back",
              title: "Back",
              description: "",
              image: backUrl,
              onUploadComplete: onBackUpload, 
            },
            {
              id: "front",
              title: "Front",
              description: "",
              image: frontUrl,
              onUploadComplete: onFrontUpload,
            }  
          ]}
        />
          <JournalCoverPreview frontUrl={frontUrl} backUrl={backUrl} />
          <div className="CoverChooseSection__button">
          <SectionButton
            parentColor={props.color}
            size="large"
            onClick={props.buttonOnClick}
          >
            Done
          </SectionButton>
          </div>
      </div>
    </Section>}
    </>
  );
}

export default CoverUploadSection;
