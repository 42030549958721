import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Address from "./Address";

function AddressSection(props) {

    return (
        <Section color={props.color} size={props.size}>
            <div className="container">
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    centered={true}
                    size={3}
                />
                <Address
                    buttonText={props.buttonText}
                    parentColor={props.color}
                    onDone={props.onDone}
                    mode={props.mode}
                />
            </div>
        </Section>
    );
}

export default AddressSection;
