import React from "react";
import ContactSection from "./../components/ContactSection";

function ContactPage(props) {
  return (
    <ContactSection
      color="white"
      size="medium"
      title="Contact Us"
      subtitle="orders@singleton.io"
      showNameField={false}
      buttonText="Send message"
    />
  );
}

export default ContactPage;
