import React, { useState } from "react";
import FormStatus from "./FormStatus";
import FormField from "./FormField";
import SectionButton from "./SectionButton";
import { Link } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";

import "./Address.scss";

function Address(props) {

    const auth = useAuth();
    const loading = !(auth.stripeUser);

    const stripeMeta = (field, defaultValue) => {
        return (auth.stripeUser && auth.stripeUser.metadata &&
            auth.stripeUser.metadata[field]) || (defaultValue != null ? defaultValue : "");}

    // State for all inputs
    const [shippingName, setShippingName] = useState(null);
    const [shippingLine1, setShippingLine1] = useState(null);
    const [shippingLine2, setShippingLine2] = useState(null);
    const [city, setCity] = useState(null);
    const [shippingState, setShippingState] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [country, setCountry] = useState(null);
    const [phone, setPhone] = useState(null);


    const saveAddress = (name, line1, line2, city, state, country, postalCode, phoneNumber) => {
        auth.updateStripeUserMetadata({
            "shippingName": name,
            "shippingLine1": line1,
            "shippingLine2": line2,
            "shippingCity": city,
            "shippingState": state,
            "shippingCountry": country,
            "shippingPostalCode": postalCode,
            "shippingPhone": phoneNumber
        }, {"address": {"line1": line1, "line2": line2,
                        "city": city, "country": country,
                        "postal_code": postalCode,
                        "state":state},
            "name": name,
            "phone": phoneNumber}).then(props.onDone());
    }

    // Whether to show errors
    // We set to true if they submit and there are errors.
    // We only show errors after they submit because
    // it's annoying to see errors while typing.
    const [showErrors, setShowErrors] = useState(false);

    // Error array we'll populate
    let errors = [];

    // Function for fetching error for a field
    const getError = field => {
        return errors.find(e => e.field === field);
    };

    // Function to see if field is empty
    const isEmpty = val => val.trim() === "";

    // Add error if line1 empty
    if (["shipping"].includes(props.mode)) {
        if ((!shippingLine1 || isEmpty(shippingLine1)) && isEmpty(stripeMeta("shippingLine1"))) {
            errors.push({
                field: "shippingLine1",
                message: "Please enter an address"
            });
        }
    }
    // Add error if city empty
    if (["shipping"].includes(props.mode)) {
        if ((!city || isEmpty(city)) && isEmpty(stripeMeta("shippingCity"))) {
            errors.push({
                field: "city",
                message: "Please enter a city"
            });
        }
    }
    // Add error if state empty
    if (["shipping"].includes(props.mode)) {
        if ((!shippingState || isEmpty(shippingState)) && isEmpty(stripeMeta("shippingState"))) {
            errors.push({
                field: "state",
                message: "Please enter a state"
            });
        }
    }

    // Handle form submission
    const handleSubmit = () => {
        // If field errors then show them
        if (errors.length) {
            setShowErrors(true);
        } else {
            // Otherwise call onSubmit with the address
            if (props.onDone) {
                saveAddress(
                    (shippingName || stripeMeta("shippingName")),
                    (shippingLine1 || stripeMeta("shippingLine1")),
                    (shippingLine2 || stripeMeta("shippingLine2")),
                    (city || stripeMeta("shippingCity")),
                    (shippingState || stripeMeta("shippingState")),
                    (country || stripeMeta("shippingCountry", "US")),
                    (postalCode || stripeMeta("shippingPostalCode")),
                    (phone || stripeMeta("shippingPhone"))       
                );
            }
        }
    };

    return (
        !loading &&
        <div className="Address">
            {props.status && props.status.message && (
                <FormStatus type={props.status.type} message={props.status.message} />
            )}

            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={shippingName == null ? stripeMeta("shippingName") : shippingName}
                        type="name"
                        placeholder="Name"
                        error={showErrors && getError("name")}
                        onChange={value => setShippingName(value)}
                    />
                )}
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={shippingLine1 == null ? stripeMeta("shippingLine1") : shippingLine1}
                        type="text"
                        placeholder="Address"
                        error={showErrors && getError("shippingLine1")}
                        onChange={value => setShippingLine1(value)}
                    />
                )}
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={shippingLine2 == null ? stripeMeta("shippingLine2") : shippingLine2}
                        type="text"
                        placeholder="Address Line 2 (optional)"
                        error={showErrors && getError("shippingLine2")}
                        onChange={value => setShippingLine2(value)}
                    />
                )}
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={city == null ? stripeMeta("shippingCity") : city}
                        type="text"
                        placeholder="City"
                        error={showErrors && getError("city")}
                        onChange={value => setCity(value)}
                    />
                )}
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={country == null ? stripeMeta("shippingCountry", "US") : country}
                        type="select"
                        placeholder="Country"
                        error={showErrors && getError("country")}
                        onChange={value => setCountry(value)}
                    />
                )}
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={shippingState == null ? stripeMeta("shippingState") : shippingState}
                        type={(country == null ? stripeMeta("shippingCountry", "US") : country) === "US" ? "select-state" : "text"}
                        placeholder="State"
                        error={showErrors && getError("state")}
                        onChange={value => setShippingState(value)}
                    />
                )}
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={postalCode == null ? stripeMeta("shippingPostalCode") : postalCode}
                        type="text"
                        placeholder="Postal Code"
                        error={showErrors && getError("postalCode")}
                        onChange={value => setPostalCode(value)}
                    />
                )}
                {["shipping"].includes(props.mode) && (
                    <FormField
                        value={phone == null ? stripeMeta("shippingPhone") : phone}
                        type="text"
                        placeholder="Phone number"
                        error={showErrors && getError("phone")}
                        onChange={value => setPhone(value)}
                    />
                )}
                <div className="field">
                    <p className="control ">
                        <SectionButton
                            parentColor={props.parentColor}
                            size="medium"
                            fullWidth={true}
                            state={
                                props.status && props.status.type === "pending"
                                    ? "loading"
                                    : "normal"
                            }
                        >
                            {props.buttonText}
                        </SectionButton>
                    </p>
                </div>
            </form>
        </div>
    );
}

export default Address;
