import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import "./CoverChooseSection.scss";
import { useCookies } from 'react-cookie';
import { useAuth } from "./../util/auth.js";
import Covers from "./Covers";
import fast from './fast.jpg';
import seasonal from './seasonal.jpg';
import customupload from './customupload.jpg';
import { useRouter } from "./../util/router.js";


function CoverChooseSection(props) {
  const [cookies, setCookie] = useCookies(['fj']);
  const auth = useAuth();
  const covers = (auth.stripeUser &&
    auth.stripeUser.metadata && auth.stripeUser.metadata.covers) || "seasonal";
  const loading = !(auth.stripeUser);

  const router = useRouter();

  // Go to page after signin
  const onCustomize = (choice) => {
      if (choice == "custom") {
        router.push("/photoupload");
      }
  };

  
  return (
    <Section color={props.color} size={props.size} id="covers">
      <div className="CoverChooseSection__container container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Covers
          buttonText="Choose"
          loading={loading}
          selected={covers}
          onChooseCover={choice => {
            setCookie('covers', choice);
            auth.updateStripeUserMetadata({"covers":choice}).then(onCustomize(choice));
          }}
          items={[
            {
              id: "seasonal",
              title: "Seasonal",
              description:
                "Seasonal covers, capturing the beauty of winter, spring, summer and fall.",
              image: seasonal
            },
            {
              id: "fast",
              title: "Fast Projects",
              description:
                "Inspiring images of buildings, technology and things. Each the result of people quickly accomplishing something ambitious together.",
              image: fast
            },
            {
                id: "custom",
                title: "Custom",
                description:
                  "Upload your own photos to add your own character, people you love or your own inspirational images. Can be updated at any time.",
                image: customupload
            }
  
          ]}
        />
      </div>
    </Section>
  );
}

export default CoverChooseSection;
