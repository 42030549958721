import React from "react";
import ContentSection from "./../components/ContentSection";
import FeaturesSection from "./../components/FeaturesSection";

function AboutPage(props) {
  return (
    <>
    <ContentSection
      color="primary"
      size="large"
      title="Founder's Journal"
      subtitle="Designed to help you work better."
    />
    <FeaturesSection
        color="white"
        size="medium"
        title="Story"
        subtitle="I like to write things down. With a pen. It helps me remember and fully internalise whatever it is I'm writing about. Over the years, I developed a system of working that helps me achieve my goals. In 2015, I decided to make a paper journal which reflects that system. Not just a diary, but a structured scratchpad that is designed to help me work the way I want to. I found it really useful. I gave copies to a bunch of friends and co-workers and they found it useful too. They also had lots of feedback and ideas which I've incorporated in several revisions. Now, you can use it too!"
      />
    </>
  );
}

export default AboutPage;
