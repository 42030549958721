import React, { Component } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

class Upload extends Component {
    state = {
        isUploading: false,
        progress: 0,
        filename: "",
        uploadUrl: "",
    };

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    handleUploadSuccess = filename => {
        this.setState({ filename: filename, progress: 100, isUploading: false });
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => {
                 this.setState({ uploadUrl: url });
                 this.props.onUploadComplete(url);})
    };
    handleUploadClick = e => {
    };

    render() {
        return (
            <div>
                <form onSubmit={e => {
                    e.preventDefault();
                }}>
                    {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                    <label className="Covers__button button is-medium is-primary">
                    Upload
                    <FileUploader
                        hidden
                        accept="image/*"
                        name="filename"
                        randomizeFilename
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}/>
                    </label>
                </form>
            </div>
        );
    }
}

export default Upload;