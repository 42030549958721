import React from "react";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import { useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import background from "./background.jpg";

function IndexPage(props) {
  const router = useRouter();
  const auth = useAuth();


  return (
    <>
      <HeroSection
        color="white"
        size="medium"
        title="founder's journal"
        subtitle="Designed to help you work better!"
        buttonText={auth.user ? "Dashboard →" : "Get Started"}
        image={background}
        buttonOnClick={() => {
          if (auth.user === false) {
            router.push("/pricing");
          } else {
            router.push("/dashboard");
          }
        }}
        downloadOnClick={() => {
          window.location.href = "http://singleton.io/2022.pdf";
        }}
      />
      <FeaturesSection
        color="white"
        size="medium"
        title="Story"
        subtitle="I like to write things down. With a pen. It helps me remember and fully internalise whatever it is I'm writing about. Over the years, I developed a system of working that helps me achieve my goals. In 2015, I decided to make a paper journal which reflects that system. Not just a diary, but a structured scratchpad that is designed to help me work the way I want to. I found it really useful. I gave copies to a bunch of friends and co-workers and they found it useful too. They also had lots of feedback and ideas which I've incorporated in several revisions. Now, you can use it too!"
      />
    </>
  );
}

export default IndexPage;
