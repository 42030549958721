import covers_json from "./covers.json";
import moment from "moment";

const currencyToSymbol = (currency) => {

 const currency_symbols = {
    'USD': '$', // US Dollar
    'EUR': '€', // Euro
    'CRC': '₡', // Costa Rican Colón
    'GBP': '£', // British Pound Sterling
    'ILS': '₪', // Israeli New Sheqel
    'INR': '₹', // Indian Rupee
    'JPY': '¥', // Japanese Yen
    'KRW': '₩', // South Korean Won
    'NGN': '₦', // Nigerian Naira
    'PHP': '₱', // Philippine Peso
    'PLN': 'zł', // Polish Zloty
    'PYG': '₲', // Paraguayan Guarani
    'THB': '฿', // Thai Baht
    'UAH': '₴', // Ukrainian Hryvnia
    'VND': '₫', // Vietnamese Dong
 };

 var result = currency_symbols[currency.toUpperCase()];
 if (!result) {
     return currency.toUpperCase();
 }
 return result;
}

const coversForStripeCustomer = (stripeCustomer, subType) => {
  const covers = (stripeCustomer &&
    stripeCustomer.metadata && stripeCustomer.metadata.covers) || "seasonal";

  const lookupSubType = subType || (stripeCustomer &&
    stripeCustomer.metadata && stripeCustomer.metadata.onboarding) || "year";

  const nextQuarter = "q" + ((moment().quarter() + 1) % 4);

  const frontUrl = (covers === "custom") ? (stripeCustomer &&
    stripeCustomer.metadata && stripeCustomer.metadata.frontUrl) || "http://singleton.io/2020front.jpg" :
    covers_json[covers][nextQuarter]["cover_url"];
  const backUrl = (covers === "custom") ? (stripeCustomer &&
    stripeCustomer.metadata && stripeCustomer.metadata.backUrl) || "http://singleton.io/2020back.jpg":
    covers_json[covers][nextQuarter]["back_url"];

  const contentUrl = (lookupSubType === "year") ? "http://singleton.io/2020.pdf" :
   (covers === "custom") ? "http://singleton.io/2020_q1.pdf" : covers_json[covers][nextQuarter]["content_url"];

  return {frontUrl: frontUrl, backUrl: backUrl, contentUrl: contentUrl};
}

export { currencyToSymbol, coversForStripeCustomer };
