import React, { useState } from "react";
import Section from "./Section";
import "./ManageSubscriptionSection.scss";
import SectionButton from "./SectionButton";
import { useAuth } from "./../util/auth.js";
import SectionHeader from "./SectionHeader";
import { useRouter } from "./../util/router.js";

function ManageSubscriptionSection(props) {

  const auth = useAuth();
  const [modal, setModal] = useState();
  const [pending, setPending] = useState();
  const router = useRouter();

  const onCancelSubscription = () => {
    auth.cancelSubscription(props.subscription);
    setPending(true);
  };

  return (
    <Section color={props.color} size={props.size}>
      <div className="ManageSubscriptionSection__container container">
        <h1 className="title">{props.title}</h1>
        <h2 className="subtitle">{props.subtitle}</h2>
        <div className="ManageSubscriptionSection__button">
        <SectionButton size="medium"
        onClick={(e) => {setModal(!modal);}}
        >Change</SectionButton>
        </div>
      </div>
      <SectionHeader
        title=""
        subtitle="&nbsp;"
        centered={false}
        size={3}
      />
      <div className="ManageSubscriptionSection__container container">

        <h1 className="title">Shipping Address</h1>
        <h2 className="subtitle">{props.shipping}</h2>
        <div className="ManageSubscriptionSection__button">
          <SectionButton size="medium"
            onClick={(e) => {router.push("/shipping");}}
          >Change</SectionButton>
        </div>

      </div>
      <div className={"modal" + (modal ? " is-active" :"")} color={props.color}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Manage Subscription</p>
            <button className="delete" aria-label="close" onClick={(e) => { setModal(false); }}></button>
          </header>
          <section className="modal-card-body">
          {props.subtitle}<br/><br/>
          You can cancel your subscription at any time. You will not receive the next quarterly journal. Any journals that have already been sent for printing have already been paid for and will still be delivered.
          </section>
          <footer className="modal-card-foot">
            <button className={"button is-danger" + (pending ? " is-loading":"")} onClick={onCancelSubscription}>Cancel Subscription Now</button>
            <button className="button" onClick={(e) => {setModal(false);}}>Cancel</button>
          </footer>
        </div>
      </div>
    </Section>
  );
}

export default ManageSubscriptionSection;
