import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";

function FaqSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          centered={true}
          size={3}
        />
        <Faq
          items={[
            {
              question: "When will my journal be delivered?",
              answer:
                "Shipping time varies by country, but typically arrive 10 days after they are sent for shipping."
            },
            {
              question: "What's your cancelation policy?",
              answer:
                "Subscriptions can be canceled at any time via the dashboard. Journals that have already been sent for printing will still be delivered (and have already been charged for).",
            },
            {
              question: "What's your refund policy?",
              answer:
                "We endeavor to make every Journal great. If there's a problem with your order please email us at orders@davidsingleton.org. We will organize replacement orders for any defective product free of additional charge or will refund any order that is defective."
            },
          ]}
        />
      </div>
    </Section>
  );
}

export default FaqSection;
