import React, {useState, useEffect, useCallback} from "react";
import {PaymentRequestButtonElement} from "@stripe/react-stripe";
import { useAuth } from "./../util/auth.js";

const ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
                color: '#aab7c4',
            },
            height: '80px'
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

const ApplePaySection = (props) => {

    const [paymentRequest, setPaymentRequest] = useState(null);
    const auth = useAuth();

    const stripe = props.stripe;
    const onPaymentMethod = props.onPaymentMethod;

    const ELEMENT_OPTIONS = {
        style: {
            paymentRequestButton: {
                type: 'buy',
                theme: 'dark',
            },
        },
    };

    useEffect(() => {
        // This is necessary as the effect runs multiple times on page load as
        // the dependencies change, but the payment request can only be set up
        // once.
        if (!auth.stripeUser || !auth.stripeUser.id) {
            return;
        }
        const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Journal - save card',
                amount: 0,
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true,
            requestShipping: true,
            shippingOptions: [
                // The first shipping option in this list appears as the default
                // option in the browser payment interface.
                {
                    id: 'free-shipping',
                    label: 'Free shipping included',
                    detail: 'Arrives in 5 to 7 days after printing',
                    amount: 0,
                },
            ],
        });

        // Check the availability of the Payment Request API first.
        paymentRequest.canMakePayment().then(result => {
            if (result) {
                paymentRequest.on('paymentmethod', async (ev) => {
                    if (onPaymentMethod) {
                        onPaymentMethod(ev);
                    } else {
                        ev.complete('success');
                    }
                });

                setPaymentRequest(paymentRequest);
            }
        });
    }, [auth, onPaymentMethod, stripe]);

    if (paymentRequest) {
        return (<div className="columns is-centered">
            <div className="column is-half">
        <PaymentRequestButtonElement options={{...ELEMENT_OPTIONS,paymentRequest }} />
        </div></div>
        )
    }

    // Render a normal traditional checkout form instead.
    return null;
};

export default ApplePaySection;