import React, { useEffect } from "react";
import { useAuth } from "./../util/auth.js";
import { useRouter } from "./../util/router.js";
import CoverUploadSection from "../components/CoverUploadSection";

function PhotoUploadPage(props) {
  const auth = useAuth();
  const router = useRouter();

  // Redirect to signin
  // if not signed in.
  useEffect(() => {
    if (auth.user === false) {
      router.push("/signin");
    }
  }, [auth, router]);

  return (
      <CoverUploadSection title="Upload your custom cover images"
      buttonOnClick={() => {
          router.push("/dashboard");
      }}
      />
  );
}

export default PhotoUploadPage;
