import React from "react";
import "./Covers.scss";

function Covers(props) {
  return (
    <div className="columns is-centered is-variable is-5">
      {props.items.map((item, index) => (
        <div className="Covers__column column" key={index}>
          <div
            className={
              "Covers__card card" +
              (item.emphasized === true ? " emphasized" : "")
            }
            style={{background:"url('" + item.image + "')", backgroundSize:"cover"}}
          >
            <div className="Covers__card-content card-content card-scrim" >
              <span className="is-size-4 has-text-weight-bold">{item.title}</span>
              <p className="Covers__description">{item.description}</p>
              <button
                className="Covers__button button is-medium is-primary"
                onClick={() => props.onChooseCover(item.id)}
              >
                {item.id == props.selected ? "✅Selected" : props.buttonText}
              </button>
            </div>
            </div>
        </div>
      ))}
    </div>
  );
}

export default Covers;
