import React, { useState } from "react";
import CheckoutForm from "./CheckoutForm";
import {injectStripe} from 'react-stripe-elements';
import { useAuth } from "./../util/auth.js";
import { useCookies } from 'react-cookie';

import "./Checkout.scss";

function Checkout(props) {
  const auth = useAuth();

  const [status, setStatus] = useState();
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [cookies, setCookie] = useCookies(['fj']);

  const onSubmit = ({ name, email, message }) => {
    setStatus({ type: "pending" });

    const onboard = cookies.onboarding;
    props.stripe
      .createPaymentMethod('card', {billing_details: {name: auth.user.displayName, email: auth.user.email}})
      .then(({paymentMethod}) => {
        setPaymentMethodId(paymentMethod.id);
        auth.createCustomer(auth.user, paymentMethod.id, onboard).then(
          r => {setStatus({ type: "" });})
      });

  };

  return (
    <>
    <div className="Checkout__container container">
    <CheckoutForm
      parentColor={props.parentColor}
      showNameField={props.showNameField}
      buttonText={props.buttonText}
      onSubmit={onSubmit}
      status={status}
    />
    </div>
    </>
  );
}

export default injectStripe(Checkout);