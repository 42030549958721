import React from "react";
import Upload from "./Upload";

import "./Covers.scss";

function UploadCovers(props) {

    return (
        <div className="columns is-centered is-variable is-5">
            {props.items.map((item, index) => (
                <div className="Covers__column column" key={index}>
                    <div
                        className={
                            "Covers__card card" +
                            (item.emphasized === true ? " emphasized" : "")
                        }
                        style={{ background: "url('" + item.image + "')", backgroundPosition:"right", backgroundSize: "contain", backgroundRepeat: "no-repeat"}}
                    >
                        <div className="Covers__card-content card-content card-scrim" >
                            <span className="is-size-4 has-text-weight-bold">{item.title}</span>
                            <p className="Covers__description">{item.description}</p>
                            <Upload onUploadComplete={item.onUploadComplete}/>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default UploadCovers;
