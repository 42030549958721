import React, { useState } from "react";
import Section from "./Section";
import "./ManageSubscriptionSection.scss";
import { useAuth } from "./../util/auth.js";
import SectionHeader from "./SectionHeader";
import { useRouter } from "./../util/router.js";
import moment from "moment";

function OrderHistorySection(props) {

  const auth = useAuth();
  const [modal, setModal] = useState();
  const [pending, setPending] = useState();
  const router = useRouter();

  const onCancelSubscription = () => {
    auth.cancelSubscription(props.subscription);
    setPending(true);
  };

  return (
    <Section color={props.color} size={props.size}>
      <div className="ManageSubscriptionSection__container container">
        <h1 className="title">{props.title}</h1>
        <table className="table">
            <thead>
                <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Status</th>
                <th>Delivers by</th>
                </tr>
            </thead>
            <tbody>
        {props.invoices.map( (invoice, index) => (
            <tr>
                <td>{moment.unix(parseInt(invoice.lines.data[0].period.start)).format("YYYY-MM-DD")}</td>
                <td>{invoice.lines.data[0].description}</td>
                <td>{invoice.metadata && (invoice.metadata.lulu_status ? JSON.parse(invoice.metadata.lulu_status).message : "pending")}</td>
                <td>{invoice.metadata && (invoice.metadata.lulu_shipping_dates ? JSON.parse(invoice.metadata.lulu_shipping_dates).arrival_max : "pending")}</td>
            </tr>
            
        ))}
        </tbody>
        </table>
      </div>
      <SectionHeader
        title=""
        subtitle="&nbsp;"
        centered={false}
        size={3}
      />
    </Section>
  );
}

export default OrderHistorySection;
