import React, { useState, useEffect, useContext, createContext } from "react";
import queryString from "query-string";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

const { REACT_APP_FIREBASE_CONFIG } = process.env;
const atob = require("atob");
var firebaseAccount = JSON.parse(atob(REACT_APP_FIREBASE_CONFIG));

if (!firebase.apps.length) {
  // Replace with your own Firebase credentials
  firebase.initializeApp(firebaseAccount);
  firebase.analytics();
}

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... update when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [stripeUser, setStripeUser] = useState(null);
  const [idToken, setIdToken] = useState(null);

  const createCustomer = (data, pmid, onboardingTo, token) => {

    var body = {
      email: data.email,
      displayName: data.displayName,
      uid: data.uid,
      paymentMethodId: pmid,
      auth: idToken || token
    };
    if (onboardingTo && onboardingTo !== "") {
      body.onboardingTo = onboardingTo;
    }

    return fetch("/.netlify/functions/create_or_update_customer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }).then(r => {
      r.json().then(json => {
        console.log(json);
        if (json) {
          setStripeUser(json);
          if (pmid) {
            firebase.analytics().logEvent('add_payment_info');
          } else {
            firebase.analytics().logEvent('begin_checkout');
          }
        }
        return json;
      });
    });
  }

  const updateStripeUserMetadata = (metadata_updates, shipping_address_updates) => {

    const body = {
      id: stripeUser.id,
      updates: metadata_updates,
      shipping_address_updates: shipping_address_updates,
      auth: idToken,
      uid: user.uid,
     };

    return fetch("/.netlify/functions/update_customer_metadata", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(r => {
      r.json().then(json => {
        if (json) {
          setStripeUser(json);
        }
        return json;
      });
    });
  }

  const cancelSubscription = (subscription) => {

    const body = {
      customer_id: stripeUser.id,
      subscription_id: subscription,
      auth: idToken,
      uid: user.uid,
    };

    return fetch("/.netlify/functions/cancel_subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(r => {
      r.json().then(json => {
        setStripeUser(json);
        firebase.analytics().logEvent('purchase_refund', {value: 1799});
        return json;
      });
    });
  }

  const createSubscription = () => {

    const body = {
      customer_id: stripeUser.id,
      auth: idToken,
      uid: user.uid,
    };

    return fetch("/.netlify/functions/create_subscription", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(r => {
      r.json().then(json => {
        setStripeUser(json);
        firebase.analytics().logEvent('ecommerce_purchase', {value: 1799});
        return json;
      });
    });
  }

  const chargeInvoice = () => {

    const body = {
      customer_id: stripeUser.id,
      auth: idToken,
      uid: user.uid,
    };


    return fetch("/.netlify/functions/charge_invoice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }).then(r => {
      r.json().then(json => {
        setStripeUser(json);
        firebase.analytics().logEvent('ecommerce_purchase', {value: 1999});
        return json;
      });
    });
  }

  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signup = (email, password, displayName, onboardingTo) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        return response.user.updateProfile({displayName: displayName})
          .then(function() {
            response.user.getIdToken(true).then(token => {
              setIdToken(token);
              createCustomer(response.user, null, onboardingTo, token);
              setUser(response.user);
            });
          },
          function (error) {});
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
        setIdToken(null);
        setStripeUser(null);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (password, code) => {
    // Get code from query string object
    const resetCode = code || getFromQueryString("oobCode");

    return firebase
      .auth()
      .confirmPasswordReset(resetCode, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        setUser(firebaseUser)
        firebaseUser.getIdToken(true).then(token => {
          setIdToken(token);
          createCustomer(firebaseUser, null, null, token);
        });
      } else {
        setUser(false);
        setIdToken(null);
        setStripeUser(null);
      }
    });

    // Subscription unsubscribe function
    return () => unsubscribe();
  }, []);

  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    createCustomer,
    stripeUser,
    updateStripeUserMetadata,
    createSubscription,
    cancelSubscription,
    chargeInvoice
  };
}

const getFromQueryString = key => {
  return queryString.parse(window.location.search)[key];
};
