import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

import "./JournalCoverPreview.scss";

function JournalCoverPreview(props) {

  const front_img_url = props.frontUrl;
  const back_img_url = props.backUrl;

  const preview_src = "http://lulu-printer.us.davidsingleton.org/preview_cover?" + 
    "front_img_url=" + encodeURI(front_img_url) + "&" +
    "back_img_url=" + encodeURI(back_img_url);
    
  return (
    <Section color={props.color} size={props.size}>
              <SectionHeader
          title="Preview"
          subtitle="← back cover | front cover →"
          centered={true}
          size={3}
        />
      <div className="columns is-centered is-variable is-5">
        <div className="JournalCoverPreview__preview column is-full">
        <img alt="" className="JournalCoverPreview__preview_img" src={preview_src} />
        </div>
    </div>
    </Section>
  );
}

export default JournalCoverPreview;
