import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import SigninPage from "./signin";
import SignupPage from "./signup";
import ForgotpassPage from "./forgotpass";
import ChangepassPage from "./changepass";
import PhotoUploadPage from "./photoupload";
import ShippingPage from "./shipping";
import { Switch, Route, Router } from "./../util/router.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { ProvideAuth } from "./../util/auth.js";
import { StripeProvider } from "react-stripe-elements";
import { CookiesProvider } from 'react-cookie';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

function App(props) {
  const [onboardingTo, setOnboardingTo] = React.useState(0)
  return (
    <CookiesProvider>
    <StripeProvider apiKey={REACT_APP_STRIPE_PUBLIC_KEY}>
    <ProvideAuth>
      <Router>
        <>
          <Navbar
            color="white"
            spaced={true}
            logo="📖"
          />

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route exact path="/shipping" component={ShippingPage} />

            <Route exact path="/pricing" component={() => <PricingPage setOnboarding={setOnboardingTo}/>} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/dashboard" component={() => <DashboardPage
              onboardingTo={onboardingTo}
              setOnboardingTo={setOnboardingTo}
              /> } />

            <Route exact path="/photoupload" component={PhotoUploadPage} />

            <Route exact path="/signin" component={() => <SigninPage onboardingTo={onboardingTo}/>} />

            <Route exact path="/signup" component={() => <SignupPage onboardingTo={onboardingTo}/>} />

            <Route exact path="/forgotpass" component={ForgotpassPage} />

            <Route exact path="/changepass" component={ChangepassPage} />

            <Route
              component={({ location }) => {
                return (
                  <div
                    style={{
                      padding: "50px",
                      width: "100%",
                      textAlign: "center"
                    }}
                  >
                    The page <code>{location.pathname}</code> could not be
                    found.
                  </div>
                );
              }}
            />
          </Switch>

          <Footer
            color="light"
            size="normal"
            logo="📖"
            copyright="© 2019 singleton.io"
          />
        </>
      </Router>
    </ProvideAuth>
    </StripeProvider>
    </CookiesProvider>
  );
}

export default App;
